<template>
  <div>
    <v-row>
      <v-col v-for="item in ParametrosOperatoriaCliente" cols="12" :key="item.id">
        <v-text-field
          v-if="item.clave === 'cbu_anr'"
          v-model="item.model"
          ref="cbutextfield"
          :label="item.nombre"
          :success="cbuSuccess"
          :loading="loadingCBU"
          :error="cbuError"
          :messages="cbuMsg"
          @change="validarCBU(item.model)"
          :hide-details="false"
          type="text"
          required
          :disabled="datosConfirmados"
          class="notranslate"
        ></v-text-field>
        <v-text-field
          v-if="item.clave === 'monto_total_solicitado'"
          v-model="item.model"
          :label="item.nombre"
          :rules="montoRules"
          :type="validarTipo(item.options)"
          required
          class="notranslate"
          :disabled="datosConfirmados"
          @input="resetCheckbox"
        ></v-text-field>
        <v-text-field
          v-if="item.clave === 'celular_contacto'"
          v-model="item.model"
          :label="item.nombre"
          :rules="reglaCel"
          :disabled="datosConfirmados"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-card v-if="ParametrosOperatoriaCliente.length < 1">
          <v-card-title>Datos Requisitos: </v-card-title>
          <v-card-text>No se encuentra habilitado por falta de parámetros </v-card-text>
        </v-card>
        <div>
          <v-checkbox
            v-if="ParametrosOperatoriaCliente.length !== 0"
            v-model="checkbox"
            :rules="[(v) => !!v || 'Debe aceptar para continuar!']"
            label="Acepto los valores"
            required
          ></v-checkbox>
          <v-row>
            <v-col>
              <v-btn
                v-if="ParametrosOperatoriaCliente.length !== 0 && !datosConfirmados"
                color="success"
                class="mr-4"
                @click="setDatosParametros"
                :disabled="
                  ParametrosOperatoriaCliente.some((el) => el.model === '') || !checkbox || !cbuSuccess || !validarMonto
                "
              >
                Confirmar Valores
              </v-btn>
              <v-alert v-if="datosConfirmados" border="left" color="green" dense outlined text type="success"
                >Los parametros han sido confirmados</v-alert
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-alert v-if="success" border="bottom" color="success" dark>
          {{ successMensaje }}
        </v-alert>
        <v-alert v-if="error" border="bottom" color="red" dark>
          <b>Ocurrió un error al guardar el Parametro de la operatoria.</b> {{ errorMensaje }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  props: ['listaParametrosOperatoriaCliente', 'parametrosOperatoria', 'lineaSeleccionada'],
  data: () => ({
    cbuMsg: '',
    cbuError: false,
    validarMonto: false,
    cbuSuccess: false,
    loadingCBU: false,
    datosParametros: [],
    datosConfirmados: false,
    valid: true,
    localidades: [],
    ParametrosOperatoriaCliente: '',
    tipoDeDocumentos: [],
    codigoIva: [],
    token: localStorage.getItem('token'),
    rules: {
      required: (value) => !!value || 'Campo requerido',
      number: (value) => /[0-9]*[.][0-9]+/.test(value) || 'El valor ingresado debe ser numérico.-',
    },
    rulesTopeReintegro: [],
    checkbox: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
  }),
  methods: {
    resetCheckbox() {
      this.checkbox = false;
    },
    validarTipo(t) {
      let r = '';
      if (t === 'int') {
        r = 'number';
      } else if (t === 'string') {
        r = 'string';
      } else {
        r = 'float';
      }

      return r;
    },
    setDatosParametros() {
      this.datosParametros = this.ParametrosOperatoriaCliente.map((el) => ({
        id: el.id,
        nombre: el.clave,
        value: el.model,
      }));
      this.$emit('enviarParametros', this.datosParametros);
      this.datosConfirmados = true;
    },
    validarCBU(clavebancariaunica) {
      this.loadingCBU = true;
      const url = `${API_URL}api/validar/cbu`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        cbu: `${clavebancariaunica}`,
      };
      axios.post(url, data, header).then((result) => {
        let msg = '';
        if (result.data.valid) {
          msg = 'CBU válido';
          this.cbuSuccess = true;
          this.cbuError = false;
        } else {
          msg = 'CBU no válido';
          this.cbuError = true;
          this.cbuSuccess = false;
        }
        this.loadingCBU = false;
        this.cbuMsg = msg;
      });
    },
    montoValido() {
      const monto = this.ParametrosOperatoriaCliente.filter((el) => el.clave === 'monto_total_solicitado');
      const montoMinimo = parseInt(this.parametrosOperatoria.find((e) => e.clave === 'monto_min_anr').valor, 10);
      const montoMaximo = parseInt(this.parametrosOperatoria.find((e) => e.clave === 'monto_max_anr').valor, 10);

      return monto[0].model >= montoMinimo && monto[0].model <= montoMaximo;
    },
  },
  computed: {
    montoRules() {
      return [
        (v) => !!v || 'Falta monto de la operación',
        (v) =>
          (v >= parseInt(this.parametrosOperatoria.find((e) => e.clave === 'monto_min_anr').valor, 10) &&
            v <= parseInt(this.parametrosOperatoria.find((e) => e.clave === 'monto_max_anr').valor, 10)) ||
          `El monto debe estar entre ${this.parametrosOperatoria.find((e) => e.clave === 'monto_min_anr').valor} y ${
            this.parametrosOperatoria.find((e) => e.clave === 'monto_max_anr').valor
          }`,
      ];
    },
    reglaCel() {
      return [
        (v) => !!v || 'Falta completar campo. ej. +54 11 12345678', // Check if the field is not empty
      ];
    },
  },
  watch: {
    checkbox() {
      this.validarMonto = this.montoValido();
    },
  },
  created() {
    this.ParametrosOperatoriaCliente = this.listaParametrosOperatoriaCliente.filter(
      (el) => el.clave === 'cbu_anr' || el.clave === 'monto_total_solicitado' || el.clave === 'celular_contacto',
    );
  },
};
</script>
<style>
.swal2-title,
.swal2-content,
.swal2-styled {
  font-family: Roboto, sans-serif !important;
}
</style>
