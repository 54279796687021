<template>
  <v-row>
    <v-col lg="12" sm="12" md="12" xl="12">
      <v-data-table
        v-if="mostrarLoading"
        :loading="mostrarLoading"
        hide-default-header
        hide-default-footer
        loading-text="Cargando... Por favor"
      >
      </v-data-table>
      <v-card v-if="!mostrarLoading">
        <v-card-title class="text-center justify-center py-4">
          <h3 class="font-weight-bold display-1">Cargar información</h3>
        </v-card-title>
        <v-card-title class="text-center justify-center py-3">
          <h3 class="font-weight-bold">
            {{ operatoria.nombre }}
          </h3>
        </v-card-title>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab href="#cliente"> Cliente </v-tab>
          <v-tab href="#documentacion"> Documentación </v-tab>
          <v-tab href="#bitacora"> Bitácora </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="cliente">
            <v-card class="mt-5" flat>
              <template v-slot:default>
                <v-container>
                  <v-col cols="12">
                    <v-form ref="form" v-model="valid">
                      <v-text-field v-model="lineaCreditoCliente[0].id" label="Legajo" disabled></v-text-field>
                      <v-text-field
                        v-model="cliente.apellido"
                        label="Apellido"
                        v-if="cliente.tipoCliente === '1'"
                      ></v-text-field>
                      <v-text-field
                        v-model="cliente.nombre"
                        label="Nombre"
                        v-if="cliente.tipoCliente === '1'"
                      ></v-text-field>
                      <v-text-field
                        v-model="cliente.razonSocial"
                        label="RazonSocial"
                        v-if="cliente.tipoCliente === '0'"
                      ></v-text-field>
                      <v-text-field v-model="cliente.dni" label="DNI" v-if="cliente.tipoCliente === '1'"></v-text-field>
                      <v-text-field
                        v-model="cliente.cuit"
                        label="CUIT"
                        v-if="cliente.tipoCliente === '0'"
                      ></v-text-field>
                      <v-select
                        v-model="cliente.sectorProductivo.id"
                        label="Sector Productivo"
                        disabled
                        required
                        :items="sectorProductivos"
                      ></v-select>
                      <v-text-field v-model="cliente.domicilioElectronico" label="Domicilio Electronico"></v-text-field>
                      <v-text-field v-model="cliente.domicilioLegal" label="Domicilio Legal"></v-text-field>
                      <v-text-field v-model="cliente.domicilioReal" label="Domicilio Real"></v-text-field>
                      <v-text-field v-model="cliente.celular" label="Nro. Contacto"></v-text-field>
                      <v-btn color="success" class="mr-4" block @click="actualizarInformacionCliente()">
                        Guardar
                      </v-btn>
                      <div>
                        <v-alert
                          v-model="alert"
                          border="left"
                          close-text="Información !"
                          color="info mt-5"
                          dark
                          dismissible
                        >
                          La información del cliente se guardó correctamente.
                        </v-alert>
                      </div>
                    </v-form>
                  </v-col>
                </v-container>
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item value="documentacion">
            <v-card class="mt-5" flat>
              <v-card-text>
                <v-alert v-if="documentos.length === 0" border="left" color="info mt-5" dark>
                  Se encuentra cargada toda la documentación requerida en esta etapa.
                </v-alert>
                <v-simple-table v-if="!mostrarLoading && documentos.length !== 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Nombre</th>
                        <th class="text-left">Vto. en Días Hábiles</th>
                        <th class="text-left">Archivo</th>
                        <th class="text-left">Observación</th>
                        <th class="text-left">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in documentos"
                        :key="item.original.id"
                        :class="{ 'disabled-row': !item.original.status }"
                      >
                        <td>{{ item.original.nombre }}</td>
                        <td>{{ item.original.vencimiento }}</td>
                        <td>
                          <v-file-input
                            v-if="!item.cliente.id"
                            v-model="item.value"
                            class="mt-5"
                            label="Subir archivo"
                            required
                            :disabled="
                              lineaCreditoCliente[0].estado_id !== 4 &&
                              lineaCreditoCliente[0].estado_id !== 1 &&
                              lineaCreditoCliente[0].estado_id !== 2 &&
                              lineaCreditoCliente[0].estado_id !== 3
                            "
                            outlined
                            dense
                            show-size
                          ></v-file-input>
                          <span v-if="item.cliente.id">{{ item.cliente.nombre }}</span>
                        </td>
                        <td>
                          <v-btn v-if="item.cliente.estado === 1" @click="getDocumentBitacora(item.cliente.id)">
                            Ver
                          </v-btn>
                        </td>
                        <td>
                          <div style="display: flex; justify-content: space-between">
                            <v-btn
                              :disabled="Number.isInteger(item.cliente.id) || item.estadoVencimiento"
                              v-on:click="subirDoc(item.value, item.original.id, item.original.nombre)"
                              color="primary"
                            >
                              <v-icon>mdi-upload</v-icon>
                            </v-btn>
                            <v-btn
                              :disabled="!item.cliente.id || !item.original.status"
                              v-on:click="verDoc(item.cliente.url)"
                              color="success"
                            >
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                            <v-btn
                              :disabled="!item.cliente.id || !item.original.status"
                              v-on:click="eliminarDoc(item.cliente.id)"
                              color="error"
                            >
                              <v-icon> mdi-delete </v-icon>
                            </v-btn>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-dialog v-model="dialog" width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="documentos.length !== 0"
                      block
                      :disabled="todosLosDocumentos"
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Enviar documentación
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="text-h6 pt-4">
                      Al aceptar enviará la documentación para su análisis.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="setEstadoTramite('Análisis')"> Aceptar </v-btn>
                      <v-btn color="error" @click="dialog = false"> Salir </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog max-width="500" v-model="dialogDoc">
                  <v-card>
                    <v-card-title>Observaciones</v-card-title>
                    <v-card-text>
                      {{ dialogDocText }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="dialogDoc = false">Ok</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="bitacora">
            <Bitacora :bitacora="bitacora"></Bitacora>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-btn block class="mt-5" color="primary" v-on:click="volver()"> Volver </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';
import Bitacora from '@/components/creditoView/Bitacora.vue';

export default {
  components: { Bitacora },
  props: ['token', 'lineaCreditoClienteId'],
  data: () => ({
    showTitleAprobados: false,
    documentos: [],
    DocCreditoAprobado: [],
    estado: 0,
    mostrarLoading: true,
    tab: [],
    valid: true,
    listaSolicitudEstado: [],
    cliente: {
      sectorProductivo: '',
      domicilioElectronico: '',
      domicilioLegal: '',
      domicilioReal: '',
      celular: '',
      telefonoFijo: '',
      cuit: '',
      dni: '',
      razonSocial: '',
      nombre: '',
      apellido: '',
    },
    alert: false,
    lineaCreditoCliente: {},
    lineaCreditoId: null,
    todosLosDocumentos: true,
    dialog: false,
    dialogDoc: false,
    dialogDocText: '',
    sectorProductivos: [],
    operatoria: '',
    clienteId: null,
    bitacora: [],
  }),
  methods: {
    async actualizarInformacionCliente() {
      const header = {
        headers: {
          token: this.token,
        },
      };
      await axios
        .put(`${API_URL}api/clientes`, this.cliente, header)
        .then((response) => {
          this.cliente = response.data.cliente;
          this.alert = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getOperatoria() {
      const url = `${API_URL}api/servicio/${this.lineaCreditoId}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          this.operatoria = response.data.lineaCredito;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    subirDoc(documentoAdjunto, documentoId, nombreDocumentoOriginal) {
      const codigoDoc = nombreDocumentoOriginal.replace(/\s/g, '');

      const extension = documentoAdjunto.name.split('.').pop().toLowerCase();

      if (['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension) && documentoAdjunto.size > 300 * 1024 * 1024) {
        // 300 MB in bytes for videos
        this.$swal.fire('Alerta', 'El video excede el tamaño máximo de 300 MB.', 'error');
        throw new Error('El video excede el tamaño máximo de 300 MB.');
      } else if (
        !['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension) &&
        documentoAdjunto.size > 10 * 1024 * 1024
      ) {
        // 10 MB in bytes for images and other documents
        this.$swal.fire('Alerta', 'El documento excede el tamaño máximo de 10 MB.', 'error');
        throw new Error('El documento excede el tamaño máximo de 10 MB.');
      }

      if (['mp4', 'webm', 'ogg', 'mov', 'avi'].includes(extension)) {
        console.log('Subiendo video...');
      } else {
        console.log('Subiendo documento...');
      }

      const timeoutId = setTimeout(() => {
        this.mostrarMensajeTimeout = true;
      }, 10000); // Set a timeout of 10 seconds
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentacionClientes`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      this.lineaCreditoId = this.lineaCreditoCliente[0].lineaCredito_id;

      const formData = new FormData();
      formData.append('codigoDoc', codigoDoc);
      formData.append('lineacreditoclienteId', this.lineaCreditoCliente[0].id);
      formData.append('lineaCredito_id', this.lineaCreditoCliente[0].lineaCredito_id);
      formData.append('documentoId', documentoId);
      formData.append('adjunto', documentoAdjunto);

      axios
        .post(url, formData, header)
        .then((response) => {
          clearTimeout(timeoutId);
          this.mostrarLoading = false;
          if (response.data.status === 'success') {
            this.getDocumentos();
            this.getBitacora();
          }
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          this.mostrarLoading = false;
          console.log(error);
        });
    },
    verDoc(url) {
      window.open(url);
    },

    eliminarDoc(documentoId) {
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentacionClientes`;
      axios.delete(url, {
        data: { documento_id: documentoId, legajo: this.$route.params.id },
        headers: { token: this.token },
      });
      this.getLineaCreditoCliente();
    },
    getDocumentos() {
      const url = `${API_URL}api/documentacionClientes/servicio/${this.lineaCreditoId}/${this.lineaCreditoClienteId}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            const documentosCliente = response.data.listaDocumentosCliente;
            let documentosPorAprobar;
            const precalificado = documentosCliente
              .filter((el) => el.original.etapa.nombre === 'Precalificación')
              .every((el) => el.cliente.estado === 2);

            this.DocCreditoAprobado = documentosCliente.filter((el) => el.original.etapa.nombre === 'Aprobado');

            if (precalificado) {
              documentosPorAprobar = documentosCliente.filter((el) => el.original.etapa.nombre === 'Calificación');
            } else {
              documentosPorAprobar = documentosCliente.filter((el) => el.original.etapa.nombre === 'Precalificación');
            }
            this.documentos = documentosPorAprobar;
            this.todosLosDocumentos =
              this.documentos.some((el) => el.cliente === '' && el.original.status !== false) ||
              (this.estado !== 4 && this.estado !== 1);
            this.mostrarLoading = false;
            this.documentos = this.documentos
              .filter((el) => el.cliente.estado !== 2)
              .filter((el) => {
                if ((el.cliente !== '' && !el.original.status) || (el.cliente === '' && el.original.status)) {
                  return true;
                }

                if (el.cliente === '' && !el.original.status) return false;
                return true;
              });
          } else {
            this.documentos = [];
            this.mostrarLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBitacora() {
      console.log('Obteniendo bitacora');
      const url = `${API_URL}api/bitacora/${this.lineaCreditoClienteId}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        console.log('Bitacora obtenida');
        const { bitacora } = response.data;
        this.bitacora = bitacora ? bitacora.reverse() : [];
      });
    },
    volver() {
      this.$router.push('/');
    },

    getCliente() {
      const url = `${API_URL}api/clientes/${this.clienteId}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.cliente = response.data.cliente;
            const { user } = response.data;
            if (user) {
              this.cliente.celular = this.cliente.celular ? this.cliente.celular : user.telefono;
              this.cliente.domicilioElectronico =
                this.cliente.domicilioElectronico === null ? user.email : this.cliente.domicilioElectronico;
            }
          } else {
            this.cliente = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListaSolicitudEstado() {
      const url = `${API_URL}api/solicitudEstado`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.listaSolicitudEstado = response.data.solicitudEstado;
      });
    },
    async setEstadoTramite(nombreEstado) {
      const estado = this.listaSolicitudEstado.filter((el) => el.nombre === nombreEstado)[0];

      const clienteId = localStorage.getItem('cliente_id');
      const formData = {
        lineaCreditoCliente_id: this.lineaCreditoCliente[0].id,
        estado: estado.id || 3,
        cliente_id: clienteId,
      };
      const header = {
        headers: {
          token: this.token,
        },
      };
      await axios
        .put(`${API_URL}api/servicio/clientes`, formData, header)
        .then((response) => {
          this.cliente = response.data.cliente;
          this.alert = true;
        })
        .catch((error) => {
          console.log(error);
        });
      this.dialog = false;
      this.$router.push('/');
    },
    async loadSectorProductivos() {
      const url = `${API_URL}api/sectoresProductivos`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.sectorProductivos = response.data.sectoresProductivos.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLineaCreditoCliente() {
      const url = `${API_URL}api/servicioClientes/${this.lineaCreditoClienteId}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.lineaCreditoCliente = response.data.lineaCreditoCliente;
            this.estado = this.lineaCreditoCliente[0].estado_id;
            this.lineaCreditoId = this.lineaCreditoCliente[0].lineaCredito_id;
            this.getOperatoria();
            this.getDocumentos();
            this.getBitacora();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDocumentBitacora(id) {
      const comments = this.bitacora.filter(
        (doc) => Number(doc.documentacionCliente_id) === id && doc.side === 'operador' && doc.color === 'warning',
      );
      if (comments.length > 0) {
        this.dialogDocText = comments[comments.length - 1]?.texto;
      } else {
        this.dialogDocText = '';
      }
      this.dialogDoc = true;
    },
  },
  created() {
    this.clienteId = localStorage.getItem('cliente_id');
    this.getListaSolicitudEstado();
    this.getLineaCreditoCliente();
    this.getCliente();
    this.loadSectorProductivos();
  },
};
</script>
<style>
.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}
</style>
